import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const PrivacyPolicy = () => {
  const siteTitle = 'Tim Optimum - Privacy Policy'
  const siteDescription = 'Pravila o zasebnosti Tim Optimum'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>
      <div id="main">
        <h1>Privacy Policy</h1>
        <p>
          The user can download and use the petSOS mobile app, depending on the
          existing smartphone or tablet, and depending on availability, download
          via the web or mobile app stores, always in the correct manner and on
          the right terms specified for selected online mobile stores: - Apple
          iOS: App Store, - Google Android: Google Play Store.
        </p>
        <p>
          The use of the petSOS mobile application is free of charge for the
          user. The user is obliged to pay for data transfer charges charged by
          the selected operator after current price list. Downloading and using
          applications is possible with the appropriate network also abroad
          (roaming), but there is a charge for transmission and use data abroad,
          according to the applicable price list of the selected transmission
          operator data abroad. The mobile application runs on the operating
          systems: iOS 10 and newer (Apple) or Android 5.0 and higher (Google).
          Due to technical limitations older versions of certain operating
          systems are not available, the functionality does not work properly or
          the functionality cannot be displayed.
        </p>
        <p>
          Tim Optimum d.o.o. under no circumstances assumes any responsibility
          and gives no guarantees to the user for possible inactivity or
          inability to use mobile applications, but for the smooth running of
          the mobile application only endeavored.
        </p>
        <p>
          The mobile application uses certain functionalities, external services
          and applications. The use of these services is subject to the terms of
          use of external services specified by the owner of the service or
          application, Tim Optimum d.o.o. however, assumes no responsibility or
          liability in this regard. Tim Optimum d.o.o. also not responsible for
          the format and content of the data, obtained through non-Tim Optimum
          doo.
        </p>
        <p>
          Tim Optimum d.o.o. is not responsible for the occasional inactivity of
          the mobile application, any inaccuracy of the information. Tim Optimum
          d.o.o. therefore, it is not liable for any damages which, e.g. arising
          from access to information, use or inability to use information or for
          any errors or defects in their content. However, this provision does
          not exclude the liability of the owner of the application for damage
          caused by the application. Owner causes the user to comply with the
          law governing the protection of users applications deemed to be
          intentionally or unintentionally caused by the user negligence.
          Information in the mobile application is for informational purposes
          only, therefore the owner of the app assumes no responsibility for any
          content errors and the accuracy and accuracy of the information that
          may have been published due to time mismatches, input errors, or other
          unpredictable causes. The user is responsible for all activities
          performed using his user account. In the event of any unauthorized use
          or reasonable suspicion that such access has occurred and must notify
          the owner immediately applications Tim Optimum d.o.o.
        </p>
        <p>
          The owner of the application is not responsible for the damage that
          the user would suffer as a result unauthorized use of the user account
          or password with which it is registered oz. logged into the mobile
          app. The user undertakes the mobile use of the application in
          accordance with these General Terms and Conditions and will provide
          secrecy and security of passwords and to comply with the rules and
          notices.
        </p>
        <p>
          The owner of the application is not responsible for any errors and
          damages that result misrepresentation of information and / or other
          activity from the user's sphere, e.g. when registering or logging into
          the mobile app. The owner Tim Optimum d.o.o. reserves the right to
          alerts changes, adds or removes content posted to the mobile app at
          any time without any notice. All users use all published content at
          their own risk, at they also need to make sure they have adequate data
          protection on their smartphone phone or tablet, as well as to properly
          protect your smartphone the phone or tablet they have loaded and used
          the PetSOS mobile app.
        </p>
        <p>
          Tim Optimum d.o.o. may, at its sole discretion, terminate it mobile
          application for business reasons. Due to technical or functional
          upgrades of the mobile application, the owner reserves the right to
          publish a new version of the mobile application. The user will be
          notified of the new version of the mobile application via the mobile
          application alone or through the store from which the mobile app was
          downloaded, or by mobile app updates can be on your smartphone or
          tablet the computer also came automatically, depending on the settings
          of the smart the user's phone or tablet.
        </p>
        <p>
          Tim Optimum d.o.o. processes personal data in accordance with
          Regulation (EU) 2016/679 Of the European Parliament and of the Council
          of 27 April 2016 on the protection of individuals with the processing
          of personal data and the free movement of such data, and repealing
          Directive 95/46 / EC (supra The General Protection Regulation data
          protection) and national data protection regulations.
        </p>
        <p>
          When using the mobile app, the app owner will process some personal
          user data and location. The application owner will process the
          specified information for the purpose of: - contacting the user for
          the purpose of the smooth use of the information submitted by the user
          to the petSOS application, user registration, and related abuses and
          other violations of these terms and conditions. For registration or to
          create a user account, the user must provide their personal
          information: email address and choose any password with the prescribed
          technical requirements. For smooth mobile use, the user will need to
          give the mobile application permission to processing information about
          their location. The mobile app needs this one information for finding
          lost animals. If the user does not want the location allowed, they
          will not be able to use these services completely.
        </p>
        <p>
          As a bidder, we undertake not to collect or sell. In accordance with
          the law we do not disclose any personal information to third parties.
          Bidder can send information and marketing notices and keeps track of
          email addresses. The e-mail address is used only for communication
          with the user when creating a user account where the user receives on
          their electronic address the confirmation mail and must be confirmed
          by the user within 48 hours. The user also uses the email address and
          password when logging into the application, where the provider checks
          for correct access to a user account that already exists. The provider
          may use the user's email address in response to the received e-mail.
          Information that users disclose when submitting emails are not
          distributed and are only used in the above the above forms. If a user
          chooses to delete their user account, they will receive a confirmation
          link on his email and when he will confirm his intention about
          deleting a user account at the latest within 48 hours, all the
          information from the databases that refers to them will have been
          permanently deleted.
        </p>
        <p>
          All information that is arbitrarily disclosed by the user through the
          form on the mobile to petSOS when posting a lost or found animal, the
          data that they add to the list or database temporarily until the user
          removes the post himself applications, or more than 90 days have
          elapsed since the release (including any patches then the provider
          automatically deletes the post and all user data, which were obtained
          on the basis of a mobile form completed by the user when you post an
          ad about a lost or found animal. To the electronic mailbox where only
          the owner and manager of the mobile are able to archive the user's
          data applications Tim Oprtimum d.o.o. The manager does not publish or
          disclose any reader personal information, except in the case of
          sweepstakes taking place at Facebook. In this case, each participant
          must agree to the public announcement the terms and conditions of the
          sweepstakes, where he / she clearly and clearly agrees to be in in
          case of participation in a prize competition, his personal information
          (name and surname, which uses it on Facebook) published at the end of
          the sweepstakes, to the extent that will be the winner. This
          information will not subsequently be used or disseminated in any way
          no form.
        </p>
        <p>
          The user of the petSOS mobile application is under no obligation to
          disclose his identity except if he wants it himself. The user is aware
          that the provider is obliged to make a reasonable request and, on a
          legal basis, to provide user information to state authorities when the
          reasons for suspicion of committing an offense that are prosecuted
          after the official are given duties. The user will be familiar with
          the mobile terms and conditions in advance applications. The user will
          voluntarily share the necessary personal information on the mobile
          petSOS applications that are critical to the purpose of the
          application is to find the lost animal and inform the user to the
          fullest extent that it is tied to the safe raising of domestic
          animals. The Bidder declares that he will handle everything due
          diligence and professionalism with sensitive information provided by
          the user volunteered on the petSOS app. This will ensure the highest
          privacy data. The Provider further undertakes to protect the personal
          data in accordance with Personal Data Protection Laws and GDPR General
          Data Protection Regulation.
        </p>
        <p>
          The petSOS mobile application uses the Facebook plugin for the purpose
          of displaying and content sharing. We have included video networks
          Facebook and YouTube channels. The petSOS application may contain
          links to sites that they are not under our control. When you click on
          a third party link, you will redirected to her website. If you visit
          any of these related websites, read their privacy notices. We are not
          responsible for policies and practices of other companies.
        </p>
        <p>
          The user can at any time request confirmation that the application
          owner is processing his personal information and the disclosure of
          such personal information is entitled to he receives this personal
          information and the right to pass it on to another controller. The
          user may request a restriction on the processing of personal data,
          objecting to the processing personal data and automated decision
          making, including by designing profiles, revoke consent given for
          various processing purposes, requires corrections or supplementing or
          deleting personal data. All of the above he may exercise his rights
          under the conditions and to the extent specified in Articles 15 to 22
          of the General data protection regulations The request for the
          exercise of the rights referred to in the first paragraph of this
          points, the user can make with a written request sent to Tim Optimum
          Ltd., Sagadinova street 15a, 2250 Ptuj with the attribution "for OOP"
          (Processing of Personal Data). The owner of the application will be in
          the event that the user's rights are claimed regarding the objection
          of data processing, withdrawal of consent, request to limit processing
          or erasure of personal information that results in the prevention of
          notification user, prevented notification within 15 days of receiving
          the objection or changes to the user's settings, but if it was a
          campaign for notification prepared before the objection is processed
          or the settings changed, there is still a chance that the user will
          still receive the latest notification. Exercise of other rights will
          be by Tim Optimum d.o.o. provide within deadlines, required by
          applicable personal data protection legislation.
        </p>
        <p>
          Provider Tim Optimum d.o.o. will treat personal information of users
          separately carefully and prevented access by unauthorized persons. In
          case of unauthorized The use of the username or password is obliged to
          notify the provider at email martin@timoptimum.com. The user has the
          right to in case unauthorized use and / or any other misuse of access
          or access. Use applications by users or any other person and / or in
          the case suspected password abuse takes all actions and other and
          other actions that you comply with in his own business judgment he
          deems it necessary to protect his rights and interests and / or for
          the protection of users' rights and interests and / or for the sake of
          protection of the rights and interests of any other person (eg
          preventing access to applications ...).
        </p>
        <p>
          All data, images and other information within the mobile application
          are subject to copyright and related rights and industrial property
          rights of Tim Optimum Ltd. as defined by the relevant legislation
          governing the protection of copyright and related rights and
          industrial property rights. By downloading or. registration in mobile
          application, the user acquires a non-transferable right of use apps
          exclusively on your smartphone. The right to use from the paragraphs
          are for non-commercial purposes only. Tim Optimum d.o.o. so on does
          not transmit to the user any other material copyrights or rights
          industrial property, nor does it entitle him to use trademarks
          trademarks, logos, corporate identity or proprietary Holding Team
          Optimum d.o.o .. User e.g. may not make copies of the mobile
          application, install applications on a server or other platform,
          deliver mobile applications rent or sublicense for its use,
          reprocessing or otherwise interfere with the operation of the mobile
          application.
        </p>
        <p>
          A user of the petSOS mobile application may file complaints about
          performance mobile applications by emailing martin@timoptimum.com The
          complaint must the user should describe in detail the behavior or. the
          error complained of. The complaint handling process is confidential.
          Possible disputes will be Tim Optimum d.o.o. and resolve the user by
          mutual agreement, and in case of failure, before the competent one
          court. In case of changes and additions to the general conditions that
          result amendments to relevant legislation or decisions of the
          competent national authorities, petSOS application manager Tim Optimum
          d.o.o. notify mobile user petSOS applications on new conditions. It is
          considered that the user was on the change or updated on the General
          Terms and Conditions informed on the date of publication of new or.
          modified or updated General Terms and Conditions on the petSOS mobile
          application. To the extent that the user would not with the new,
          modified or amended general terms agreed that the user will be
          prevented from using the mobile application or limited. Tim Optimum
          d.o.o. can remove at any time without prior notice to users for
          modifications and additions to the general terms and conditions that
          are without prejudice to rights and / or user obligations (eg
          typographical errors, obvious errors in the text), These terms and
          conditions shall be published on the day the application is officially
          released and available to the user.
        </p>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
